import '/assets/styles/modals/mobile-menu.scss';
import classNames from "classnames";
import { useMemo } from 'react'

// Hooks
import useAccountMenuItems from '/hooks/account-menu-items';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link'
import Icon from '@wearetla/tla-essentials-tools/partials/icon'

// Context
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';

const MobileMenuModal = ({ closeBtn, close }) => {
	const accountMenuItems = useAccountMenuItems();
	const { userData, loggedIn } = useAuth();
	const { openModal } = useModals();
	const { routes } = useNavigator();

	const menuItems = useMemo(() => {
		const accountItems = accountMenuItems.filter(i => i.route?.requiresLogin);
		const nonAccountItems = accountMenuItems.filter(i => !i.route?.requiresLogin);

		return [
			...(loggedIn ? accountItems : [
				{
					tag: 'button',
					type: 'button',
					// className: 'no-arrow',
					onClick: () => {
						openModal('auth');
					},
					title: 'Üye Ol / Giriş Yap',
					icon: 'user',
				}
			]),
			{
				tag: 'link',
				route: routes.page,
				params: { slug: 'hakkimizda', id: 1 },
				icon: 'info',
				title: 'Hakkımızda',
			},
			// {
			// 	tag: 'link',
			// 	route: routes.superMuzikFest,
			// 	icon: 'help',
			// 	title: routes.superMuzikFest.head.title,
			// },
			{
				tag: 'link',
				route: routes.contact,
				icon: 'phone',
				title: routes.contact.head.title,
			},
			...nonAccountItems,
		]
	}, [accountMenuItems, loggedIn, routes]);

	return (
		<>
			{closeBtn}
			<div className="modal-body">
				<strong className="menu-title">
					{userData ? <>Merhaba {userData?.name}</> : <>Menü</>},
				</strong>
				<ul className="menu-list">
					{menuItems.map((item, nth) => (
						<MenuItem
							item={item}
							close={close}
							key={nth} />
					))}
				</ul>
			</div>
		</>
	)
}

export const MenuItem = ({ item, close }) => {
	const { tag, active, className: rawClassName, title, icon, ...props } = item ?? {};
	const ElemTag = useMemo(() => {
		switch(tag) {
			case 'button': return 'button';
			default: return Link
		}
	}, [tag]);

	const className = useMemo(() => (
		classNames(
			'item-link',
			rawClassName,
			{
				active: active
			}
		)
	), [rawClassName, active]);

	return <li className="menu-item">
		{item &&
			<ElemTag
				onClick={() => {
					(props.onClick ?? (() => new Promise((resolve) => { resolve(); })))().then(() => {
						close();
					})
				}}
				className={className}
				{...props}>
				<span className="item-contentwrap">
					{!!icon &&
						<Icon className="item-icon" name={icon} />
					}
					{title}
				</span>
			</ElemTag>
		}
	</li>
}

MobileMenuModal.modalConfig = {
	containerClass: 'modal-mobile-menu mobile-full',
}

export default MobileMenuModal