import { useMemo } from "react";

// Context
import { useNavigator } from "@wearetla/tla-essentials-tools/utilities/navigator";
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useApplication } from '/utilities/application';

export const useAccountMenuItems = () => {
	const { routes } = useNavigator();
	const { isApplication } = useApplication();
	const { logout, userData } = useAuth();

	const accountMenu = useMemo(() => ([
		...Object.keys(routes.account.children).map((routeKey) => ({
			...routes.account.children[routeKey],
			key: routeKey
		})).filter(route => (
			!route.hideFromMenu &&
			!(
				route.hideOnWeb &&
				!isApplication
			) &&
			!(
				route.allowedRoles &&
				!route.allowedRoles.includes(userData?.role)
			)
		)).map((route) => ({
			navLink: true,
			exact: false,
			tag: 'link',
			title: route.head.title,
			route: route,
			icon: route.menuIcon,
		})),
		{
			tag: 'button',
			type: 'button',
			className: 'no-arrow',
			onClick: logout,
			title: 'Çıkış',
			icon: 'logout',
		}
	]), [routes, userData])

	return accountMenu;
}

export default useAccountMenuItems;